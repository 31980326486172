import React, { useState } from 'react';
import ArrowIcon from '../../assets/ArrowIcon';
import './style.css';

const Home = () => {
    const [isMoreShown, setIsMoreShown] = useState(false);

    return <div style={{ display: 'flex', justifyContent: 'center', padding: '12px 12px 100px 12px' }}>
        <div style={{ maxWidth: 800 }}>
            <div style={{ marginTop: 'calc(25vh - 100px)' }}>
                <h1>Parker Phair</h1>
                <h3>Systems Engineer</h3>
                <p>
                    Versatile Systems Engineer with 5+ years of experience spanning
                    Web Development, Cloud Engineering, DevOps, DB Administration,
                    and UX Design, delivering innovative solutions under the
                    aggressive timelines and high expectations of the professional
                    sports environment. Dedicated to continuous learning and known
                    for developing cutting-edge features with a focus on efficiency
                    and scalability. 
                </p>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <button
                        onClick={() => setIsMoreShown(!isMoreShown)}
                        className={`toggle-resume-btn main-btn${isMoreShown ? ' compressed' : ''}`}
                        style={{ height: 50, width: 50, borderRadius: 25 }}
                    >
                        <ArrowIcon/>
                    </button>
                </div>
            </div>
            <div className={`resume-body${isMoreShown ? ' expanded' : ''}`}>
                <h3>Experience</h3>
                <div className='resume-dated-line'>
                    <h4>Football Systems Engineer</h4>
                    <h4>Aug 2019 - Present</h4>
                </div>
                <h4 style={{ fontStyle: 'italic' }}>Indianapolis Colts</h4>
                <ul>
                    <li>
                        Upgraded ReactJS project to a Progressive Web App,
                        providing users an offline experience on the web by
                        caching build files and network calls on the client
                        device using Workbox, Chrome’s PWA service worker.
                    </li>
                    <li>
                        Engineered a video-streaming workflow allowing users
                        to upload and stream video files directly on an
                        athlete’s web profile. This upload, encode, encrypt,
                        and streaming process demonstrated proficiency in a
                        wide range of Azure services, including Blob Storage,
                        Containerized Functions, Event Grid, and Media Services.
                    </li>
                    <li>
                        Developed a similarity algorithm to find comparable
                        players by utilizing root-mean-square deviation to
                        aggregate their key measurables and output a reliable
                        similarity score. Implemented the algorithm as a stored
                        procedure on SQL Server and displayed the results in
                        an interactive radar chart.
                    </li>
                    <li>
                        Elevated the football dev team’s efficiency by
                        implementing CI/CD processes to offload build and
                        deployment tasks to the Cloud using GitHub Actions.
                        Utilized Azure Deployment Slots’ auto-swap feature to
                        eliminate deployment downtime, streamlining project
                        delivery.
                    </li>
                    <li>
                        Enabled users to conduct collaborative, real-time
                        exercises over the web by implementing WebSockets via
                        Microsoft’s SignalR libraries on the React frontend
                        and ASP.NET Core backend projects.
                    </li>
                </ul>
                <div className='resume-dated-line'>
                    <h4>Software Developer Intern</h4>
                    <h4>May 2019 - Aug 2019</h4>
                </div>
                <h4 style={{ fontStyle: 'italic' }}>Indianapolis Colts</h4>
                <ul>
                    <li>
                        Implemented ETL process for unstructured player
                        headshot data by scraping images from college
                        roster websites for over 10,000 student athletes
                        using a timer-scheduled Azure Function written
                        in C#.
                    </li>
                    <li>
                        Facilitated the roster cut-down process through
                        full-stack development of a player ranking React
                        web app used by coaching, analytics, and scouting
                        personnel. This tool has been used every season
                        since 2019.
                    </li>
                    <li>
                        Responded to feature requests by creating and
                        presenting both low and high-fidelity prototypes
                        to stakeholders on a weekly basis.
                    </li>
                </ul>
                <div className='resume-dated-line'>
                    <h4>Student Computer Programmer</h4>
                    <h4>Sep 2017 - Jan 2019</h4>
                </div>
                <h4 style={{ fontStyle: 'italic' }}>BYU Creative Works</h4>
                <ul>
                    Developed a language learning iOS app in XCode using
                    Swift. Integrated external frameworks like Google Firebase
                    on the backend.
                </ul>
                <h3>Skills</h3>
                <p>
                    SDLC, Agile, Jira, OOP, MVC, React, Node, Express.js, HTML/CSS,
                    C#, ASP.NET, Entity Framework, Swagger, Python, FastAPI,
                    Visual Studio, VS Code, SSMS, DBeaver, SQL Server, SQLite, Snowflake,
                    Data Factory, Azure, GCP, Docker, Git, Balsamiq, PowerBI, WebSockets,
                    SignalR, Service Workers, OAuth
                </p>
                <h3>Certifications</h3>
                <ul>
                    <li>
                        <div className='resume-dated-line'>
                            <a
                                href='https://learn.microsoft.com/en-us/users/parkerp-7352/credentials/6cd930d97f4da43c'
                                target='_blank'
                                rel='noreferrer'
                            >
                                Microsoft Certified: Azure Data Fundamentals (DP-900)
                            </a>
                            <span>Dec 2023</span>
                        </div>
                    </li>
                    <li>
                        <div className='resume-dated-line'>
                            <span>HackerRank: Problem Solving - Gold Level Badge</span>
                            <span>Feb 2023</span>
                        </div>
                    </li>
                    <li>
                        <div className='resume-dated-line'>
                            <span>HackerRank: Problem Solving (Basic) Skill Assessment</span>
                            <span>Sep 2020</span>
                        </div>
                    </li>
                </ul>
                <h3>Education</h3>
                <div className='resume-dated-line'>
                    <h4>Indiana University / Purdue University - Indianapolis</h4>
                    <h4>Dec 2020</h4>
                </div>
                <h4 style={{ fontStyle: 'italic' }}>B.A. Applied Computer Science</h4>
                <ul>
                    <li>Spring 2020 Dean’s List High Honors</li>
                </ul>
            </div>
        </div>
    </div>
}

export default Home;