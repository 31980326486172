import { useState, useMemo } from 'react'
import useResizeObserver from 'use-resize-observer'
import _ from 'lodash'

const useDebouncedResizeObserver = (wait, isLeadingIgnored) => {
    const [size, setSize] = useState({});
    const onResize = useMemo(() => _.debounce(setSize, wait, { leading: !isLeadingIgnored }), [
      wait,
      isLeadingIgnored
    ]);
    const { ref } = useResizeObserver({ onResize });
  
    return { ref, ...size };
};

export default useDebouncedResizeObserver;