import { useEffect, useState } from 'react';
import StarsScreenSaver from 'react-stars-screensaver';
import { HexColorPicker } from 'react-colorful';
import useDebouncedResizeObserver from '../../hooks/useDebouncedResizeObserver';
import './style.css';

const starsContainerWidthMax = 600;
const starsContainerHeightMax = 300;

const Starscape = () => {
  const [color, setColor] = useState('#013369');
  const [starsWidth, setStarsWidth] = useState(starsContainerWidthMax);
  const [starsHeight, setStarsHeight] = useState(starsContainerHeightMax);

  const {
    ref: starsContainerRef,
    width: starsContainerWidth,
    height: starsContainerHeight
  } = useDebouncedResizeObserver(500);

  useEffect(() => {
    if (starsContainerWidth && starsContainerWidth < starsContainerWidthMax) {
      setStarsWidth(starsContainerWidth);
    } else {
      setStarsWidth(starsContainerWidthMax);
    }
  }, [starsContainerWidth]);

  useEffect(() => {
    if (starsContainerHeight && starsContainerHeight < starsContainerHeightMax) {
      setStarsHeight(starsContainerHeight);
    } else {
      setStarsHeight(starsContainerHeightMax);
    }
  }, [starsContainerHeight]);

  return <div
    ref={starsContainerRef}
    style={{ display: 'flex', flexDirection: 'column', alignItems: 'safe center', justifyContent: 'center', height: '100%' }}
  >
    <div className='starscape-container'>
      <p>
        Check out my NPM package: React Stars Screensaver!<br/>
        Click <a
          target='_blank'
          rel='noreferrer'
          href={'https://www.npmjs.com/package/react-stars-screensaver'}
        >
          here
        </a> for more info about the package and instructions on how use it in your own React project.
      </p>
      <StarsScreenSaver
        // width={starsContainerWidth > starsContainerWidthMax ? starsContainerWidthMax : starsContainerWidth}
        width={starsWidth}
        // height={starsContainerHeight > starsContainerHeightMax ? starsContainerHeightMax : starsContainerHeight}
        height={starsHeight}
        backgroundColor={color}
      />
      <div style={{ marginTop: 8 }}>
        <HexColorPicker
          color={color}
          onChange={setColor}
        />
      </div>
    </div>
  </div>
}

export default Starscape;